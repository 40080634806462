<template>
    <div class="container">
        <div class="ranking">
            <div class="title">
                <span>概况统计</span>
                <div class="inline">
                    <el-date-picker
                            v-model="time"
                            type="daterange"
                            @change="getTime"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                    <b @click="download">数据导出</b>
                </div>
            </div>

            <div class="changeStoreRanking">
                <div class="flex justify-between">
                    <span>营业额</span>
                    <span>实际收款金额</span>
                    <span>新增会员数</span>
                    <span>套餐销量</span>
                    <span>服务销量</span>
                    <span>商品销量</span>
                </div>
                <div class="flex justify-between">
                    <span>￥{{ranking.turnover}}</span>
                    <span>￥{{ranking.collection}}</span>
                    <span>{{ranking.newMember}}(人)</span>
                    <span>{{ranking.openCard}}(个)</span>
                    <span>{{ranking.setMealSale}}(个)</span>
                    <span>{{ranking.productSale}}(个)</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading:false,
                years:'',
                days:7,
                type: 'day',
                time:[],
                ranking:[],//商品排行
                start_date:'',//商品开始时间
                end_date:'',//商品结束时间
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                // token:''
            };
        },
        mounted() {
            this.getSurveyData();

        },
        methods: {
            getSurveyData(){
                this.loading = true
                this.$api.statistics.surveyRanking({
                    start_date: this.start_date,
                    end_date: this.end_date
                }).then(res => {
                    console.log(res.data)
                    this.ranking = res.data
                    this.loading = false
                })
            },
            getTime(){
                if (this.time == null){
                    this.start_date = ''
                    this.end_date = ''
                }else {
                    this.start_date = this.time[0]
                    this.end_date = this.time[1]
                }
                this.getSurveyData()
            },
            //品牌类型切换
            brandHandleClick(){
                console.log(this.goodsType)
                this.start_date = ''
                this.end_date = ''
                this.getSurveyData()
            },
            brandClassifyHandleClick(){
                console.log(this.sort1)
                this.start_date = ''
                this.end_date = ''
                this.getSurveyData()
            },
            download(){
                this.$api.statistics.downloadSurvey({
                    start_date:this.start_date,
                    end_date:this.end_date,
                }).then(res => {
                    console.log(res)
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: "application/vnd.ms-excel"
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl)
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '概况统计.xls');
                    a.click();
                })
            },
        },

    };
</script>

<style scoped lang="scss">
    @import "../../assets/common.scss";

    .container{
        .ranking{
            margin: 20px 0;
            padding: 20px;
            .title:before{
                background: #FF9000;
            }
            .title .inline{
                display: inline-block;
                margin-left: 20px;
            }
            .tabs{

            }
        }
    }
    .ranking .el-range-editor.el-input__inner{
        width: 300px;
    }
    .ranking  b{
        display: inline-block;
        width: 100px;
        font-weight: normal;
        line-height: 36px;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
        margin-left: 20px;
        font-size: 16px;
        color: #17283C;
        border: 1px solid #808080;
    }
    .title{
        position: relative;
        font-size: 16px;
        color: #3C3F42;
        padding-left: 15px;
        margin-bottom: 25px;
    }
    .title:before{
        position: absolute;
        content: '';
        width: 3px;
        height: 17px;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
    }
    .ranking /deep/ .el-tabs--border-card  .el-tabs__content{
        padding: 0;
    }
    .changeStoreRanking{
        line-height: 50px;
    }
    .changeStoreRanking span{
        width: 100px;
        color: #909399;
        font-size: 14px;
    }
    .changeStoreRanking div:first-child span{
        font-weight: bold;
    }
</style>
